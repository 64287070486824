import React, {useEffect, useState} from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

import style from '../css/index.css'

import hero_1 from '../assets/images/hero/hero_1.jpg';
import hero_2 from '../assets/images/hero/hero_2.jpg';
import hero_3 from '../assets/images/hero/hero_3.jpg';
import hero_4 from '../assets/images/hero/hero_4.jpg';

import globe from '../assets/images/globe-solid.svg';
import handshake from '../assets/images/handshake-solid.svg';
import check from '../assets/images/check-solid.svg';


const Index = () => {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  const [image, setImage] = useState(0);

  const images = [ hero_1, hero_2, hero_3, hero_4 ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (image === images.length - 1 ) {
        setImage(0);
      } else {
        setImage(image + 1);
      }
    }, 3000);
    
    return () => {
      clearInterval(interval);
    };
  }, [image]);

  return (
  <Layout>
    
    <section id="stats" className="py-20 ">
      <div className='container mx-auto '>
        <div className='text text-4xl font-semibold ml-4'>O nama</div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-8">
      <div className="container mx-auto text-center">
        <div className="text-4xl lg:text-5xl mt-6 font-semibold">
        Mi smo CICO,<br/>
        mi stvaramo rješenja.
        </div>
        <div className="text-md lg:text-md mt-6 text-gray-600 font-normal">
        Ono što nas izdvaja - proizvodi koji su se svojom kvalitetom etablirali na tržištu i osiguravaju visoku potražnju kod krajnjih kupaca. Ne prodajemo obična maziva i ne vodimo agresivnu politiku cijena. Mi smo tvrtka koja se bori za svoje proizvode jer su jedinstveni.
        </div>
        <div className="text-md lg:text-md mt-6 text-gray-600 font-normal">
        CICO® proizvodi – Sintetička maziva i konzervansi visokih performansi.
        </div>
        <div className="text-md lg:text-md mt-6 text-gray-600 font-normal">
        CICO® proizvodi koriste se diljem svijeta više od 40 godina. Počeci sežu u 1980. godinu. Kemičarka dr. A. Herczeg razvio je jedinstvenu formulu koja je postala osnova za širok raspon visokoučinkovitih maziva i konzervansa za širok raspon primjena. Legendarna reputacija CICO® proizvoda temelji se na njihovoj izvanrednoj i stabilnoj kvaliteti, stalnom poboljšanju jednostavnosti upotrebe i najsuvremenijoj tehnologiji. Primjena diljem svijeta sa svojim dugoročnim uvjerljivim rezultatima i prodajom usmjerenom na kupce čini osnovu za dugoročne dobre međunarodne poslovne odnose.
        </div>
        <div className="text-md lg:text-md mt-6 text-gray-600 font-normal">
        CICO® proizvode diljem svijeta distribuira CICO SLT GmbH.
        </div>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-20">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl mb-12">Vrijednost</p>
              <div className="text-md lg:text-md mt-6 text-gray-600 font-normal text-center" >
                Našim klijentima nudimo jedinstvenu tehnologiju i punu podršku. Nalazimo rješenja i držimo riječ.
              </div>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl mb-12">Odgovornost</p>
              <div className="text-md lg:text-md mt-6 text-gray-600 font-normal text-center" >
                Sveobuhvatna sigurnost u skladu s ekonomskom, ekološkom i energetskom politikom.
              </div>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl mb-12">Poštovanje</p>
              <div className="text-md lg:text-md mt-6 text-gray-600 font-normal text-center" >
                Cijenimo naše partnere i odgovorni smo prema njima. Poštovanje i povjerenje temelj je naše poslovne politike.
              </div>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <section id="stats" className="py-20 lg:pt-32 lg:pb-32">
      <VisibilitySensor
              active={!viewPortEntered}
              onChange={isVisible => {
                if (isVisible) {
                  setViewPortEntered(true);
                }
              }}
              delayedCall
            >
        <div className="container mx-auto text-center">
          <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
            <div className="w-full sm:w-1/4">
              <StatsBox primaryText={<div className='stats-image'><img  src={globe} /></div>} secondaryText="Prodaja širom svijeta" />
            </div>
            <div className="w-full sm:w-1/4">
              <StatsBox primaryText={<CountUp end={42} start={viewPortEntered ? null : 0} />} secondaryText="Godine na tržištu" />
            </div>
            <div className="w-full sm:w-1/4">
              <StatsBox primaryText={<div className='stats-image small'><img  src={handshake} /></div>}secondaryText="Zadovoljni kupci" />
            </div>
            <div className="w-full sm:w-1/4">
              <StatsBox primaryText={<CountUp end={20} suffix='+' start={viewPortEntered ? null : 0}/>} secondaryText="Inovativnih proizvoda" />
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </section>
    
    {/*
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Market Analysis</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Our team of enthusiastic marketers will analyse and evaluate how your company stacks
            against the closest competitors
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    */}
    <section className="container mx-auto md:my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Kvaliteta koja vrijedi.</h3>
      <p className="mt-8 text-xl font-light">
        Ukližimo u bolje sutra.
      </p>
      <p className="mt-8">
        <a className="px-4" href="/kontakt">
          <Button size="xl" border>Kontaktirajte nas</Button>
        </a>
      </p>
    </section>
  </Layout>)
}

export default Index;
